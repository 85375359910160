<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="modalTitle"
    :modal-append-to-body="false"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'100px'">
        <el-form-item label="字典类型" prop="dataType">
          <el-select v-model="formModel.dataType">
            <el-option label="字典目录" value="1"></el-option>
            <el-option label="数据" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属目录" prop="parentId">
          <el-select
            v-model="formModel.parentId"
            filterable
            remote
            clearable
            placeholder="请输入关键词"
            :remote-method="queryMenu"
            style="width:300px"
          >
            <el-option
              v-for="dataDictionary in dataDictionaryList"
              :key="dataDictionary.id"
              :label="dataDictionary.name + '(' + dataDictionary.sortNo + ')'"
              :value="dataDictionary.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="formModel.name" placeholder="请输入名称" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="数值" prop="value">
          <el-input v-model="formModel.value" placeholder="请输入数值" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="显示序号" prop="sortNo">
          <el-input v-model="formModel.sortNo" placeholder="请输入显示序号" style="width:300px"></el-input>
        </el-form-item>

        <el-form-item label="拓展字段1" prop="expand1">
          <el-input
            v-model="formModel.expand1"
            placeholder="请输入拓展字段1"
            style="width:100%"
            maxlength="28"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="拓展字段2" prop="expand2">
          <el-input
            v-model="formModel.expand2"
            placeholder="请输入拓展字段2"
            style="width:100%"
            maxlength="36"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="拓展字段3" prop="expand3">
          <el-upload
            class="avatar-uploader"
            name="photoFile"
            :action="uploadUrl"
            :show-file-list="false"
            :headers="headers"
            :data="uploadData"
            :on-success="handleShareSuccess"
            :before-upload="beforeShareUpload"
          >
            <img v-if="fileExpand3Url" :src="fileExpand3Url" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import dataDictionaryApi from "@/api/sys/dataDictionary";

import { getToken } from "@/utils/auth"; // get token from cookie

export default {
  props: ["dictId", "modalTitle", "catalog"],
  data() {
    var validateValue = (rule, value, callback) => {
      if (this.formModel.dataType == "2" && value === "") {
        callback(new Error("类型为数据时，值不能为空!"));
      } else {
        callback();
      }
    };

    return {
      formModel: {},
      ruleValidate: {
        name: [{ required: true, message: "名称不能为空", trigger: "blur" }],
        value: [{ validator: validateValue, trigger: "blur" }],
        sortNo: [
          { required: true, message: "显示序号不能为空", trigger: "blur" },
        ],
        dataType: [
          {
            required: true,
            message: "字典类型不能为空",
            trigger: "blur",
          },
        ],
      },
      dataDictionaryList: [],
      showDialog: true,
      loading: false,
      submitting: false,
      uploadUrl: Constant.serverUrl + "/uploadPicture",
      uploadData: {
        subFolder: "dataDictionary",
      },
      fileUrl: "",
      headers: {
        Authorization: getToken(),
      },
      typeList: [],
      scenesList: [],
      fileExpand3Url: "",
    };
  },
  computed: {
    dataDictionaryListFilter() {
      var list = [];

      var map = {};

      this.dataDictionaryList.forEach((item) => {
        map[item.id] = item;
      });

      for (var key in map) {
        list.push(map[key]);
      }

      return list;
    },
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return dataDictionaryApi.add(self.formModel);
            } else {
              return dataDictionaryApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
    queryMenu(keywords, limit) {
      var formData = new FormData();
      formData.append("keywords", keywords);
      formData.append("dataType", "1");
      formData.append("excludeId", this.formModel.id);
      formData.append("limit", limit | 10);

      return dataDictionaryApi.query(formData).then((response) => {
        var jsonData = response.data;

        if (jsonData.result) {
          this.dataDictionaryList = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },

    handleShareSuccess(res, file) {
      var self = this;
      self.formModel.expand3 = res.data;
      self.fileExpand3Url =
        res.data + "?x-oss-process=image/resize,m_lfit,h_200,w_200";
    },
    beforeShareUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isGIF = file.type === "image/gif";
      const isPNG = file.type === "image/png";
      const isBMP = file.type === "image/bmp";
      // const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isGIF && !isPNG && !isBMP) {
        this.$message.error("上传图片只能是 JPG/GIF/PNG/BMP 格式!");
      }
      // if (!isLt2M) {
      //   this.$message.error("上传图片大小不能超过 2MB!");
      // }
      //return isJPG && isLt2M;
      return isJPG || isGIF || isPNG || isBMP;
    },
  },
  async mounted() {
    var self = this;
    self.loading = true;

    await this.queryMenu("", 1000);

    (function () {
      if (self.dictId.length == 0) {
        return dataDictionaryApi.create();
      } else {
        return dataDictionaryApi.edit(self.dictId);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;

          let expand3 = self.formModel.expand3;
          if (expand3 != null) {
            self.fileExpand3Url =
              expand3 + "?x-oss-process=image/resize,m_lfit,h_200,w_200";
          }

          // 增加初始值
          if (jsonData.data.parentId != null) {
            self.formModel.parentId = jsonData.data.parentId;
            // self.dataDictionaryList.push({
            //   id: jsonData.data.parentId,
            //   name: jsonData.data.parentName
            // });
          } else if (this.catalog != null && this.catalog.id != null) {
            console.log(this.catalog);
            self.formModel.parentId = this.catalog.id;
            self.formModel.dataType = "2";

            // self.dataDictionaryList.push({
            //   id: this.catalog.id,
            //   name: this.catalog.name
            // });
          }
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
};
</script>